<template>
  <div class="test-builder-1">
    <b-container>
      <b-row>
        <b-col cols="12" lg="6" offset-lg="3"
               class="over-pad">
          <p class="m-0">
            {{ stepText(currentStep) }} <font-awesome-icon v-show="stepText(currentStep) === 'Finished'" icon="check-circle" class="green"
                                                           size="sm" />
          </p>
          <h3 id="form-name">
            {{ getFormName(currentStep) }}
          </h3>
          <b-progress :value="currentStep" :max="max" height="8px"
                      class="my-2" />
          <b-row class="background-white builder-question my-4 p-3 py-4">
            <b-col v-show="currentStep === 1" id="step-1" cols="12">
              <p class="heavy-font">
                Enter your info to get started
              </p>
              <b-form>
                <b-form-group
                  id="input-group-1"
                  label="First Name:"
                  label-for="first-name"
                  class="mb-1">
                  <b-form-input
                    id="first-name"
                    v-model="infoForm.firstName"
                    v-validate="{ required: true, min: 2 }"
                    name="First Name"
                    :class="{ 'is-invalid': errors.has('First Name') }" />
                  <b-row class="stay-thick">
                    <b-col>
                      <span id="first-name-errors" class="small red-text">{{ errors.first('First Name') }}</span>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group id="input-group-2" label="Last Name:" label-for="last-name"
                              class="mb-1">
                  <b-form-input
                    id="last-name"
                    v-model="infoForm.lastName"
                    v-validate="{ required: true, min: 2 }"
                    name="Last Name"
                    :class="{ 'is-invalid': errors.has('Last Name') }" />
                  <b-row class="stay-thick">
                    <b-col>
                      <span id="last-name-errors" class="small red-text">{{ errors.first('Last Name') }}</span>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group id="input-group-3" label="Company Email:" label-for="email"
                              class="mb-1">
                  <b-form-input
                    id="email"
                    v-model="infoForm.email"
                    v-validate="{ required: true, email }"
                    type="email"
                    name="E-mail"
                    :class="{ 'is-invalid': errors.has('E-mail') }" />
                  <b-row class="stay-thick">
                    <b-col>
                      <span id="email-errors" class="small red-text">{{ errors.first('E-mail') }}</span>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group id="input-group-3" label="Phone Number:" label-for="phone"
                              class="mb-1">
                  <b-form-input
                    id="phone"
                    v-model="infoForm.phone"
                    v-validate="{ required: true, min: 10 }"
                    name="Phone"
                    :class="{ 'is-invalid': errors.has('Phone') }" />
                  <b-row class="stay-thick">
                    <b-col>
                      <span id="phone-errors" class="small red-text">{{ errors.first('Phone') }}</span>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group id="input-group-3" label="Job Title:" label-for="title"
                              class="mb-1">
                  <b-form-input
                    id="title"
                    v-model="infoForm.title"
                    v-validate="{ required: true, min: 2 }"
                    name="Title"
                    :class="{ 'is-invalid': errors.has('Title') }" />
                  <b-row class="stay-thick">
                    <b-col>
                      <span id="title-errors" class="small red-text">{{ errors.first('Title') }}</span>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group id="input-group-3" label="Company:" label-for="company"
                              class="mb-1">
                  <b-form-input
                    id="company"
                    v-model="infoForm.company"
                    v-validate="{ required: true, min: 2 }"
                    name="Company"
                    :class="{ 'is-invalid': errors.has('Company') }" />
                  <b-row class="stay-thick">
                    <b-col>
                      <span id="company-errors" class="small red-text">{{ errors.first('Company') }}</span>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-row class="my-3">
                  <b-col cols="6" offset="6">
                    <b-button id="step-1-next-button" variant="primary" block
                              :class="{ 'disabled': !isFormInValid(infoForm) || errors.any() }"
                              @click="next(infoForm)">
                      Next
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-col>
            <b-col v-show="currentStep === 2" id="step-2" cols="12">
              <b-form @submit.stop.prevent>
                <p>Name this test so you can easily find the results later</p>
                <b-form-group
                  id="name-form-group"
                  label="Test Name:"
                  label-for="test-name"
                  description="Example New Hires: San Diego Facility"
                  class="mb-1">
                  <b-form-input
                    id="test-name"
                    v-model="nameForm.testName"
                    v-validate="{ required: true, min: 6 }"
                    name="Test Name"
                    :class="{ 'is-invalid': errors.has('Test Name') }" />
                  <b-row class="stay-thick">
                    <b-col>
                      <span id="test-name-errors" class="small red-text">{{ errors.first('Test Name') }}</span>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-row class="my-3">
                  <b-col cols="6">
                    <b-button variant="outline-primary" block @click="back(nameForm)">
                      Back
                    </b-button>
                  </b-col>
                  <b-col cols="6">
                    <b-button id="step-2-next-button" variant="primary" block
                              :class="{ 'disabled': !isFormInValid(nameForm) || errors.items.length }"
                              @click="next(nameForm)">
                      Next
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-col>
            <b-col v-show="currentStep === 3" id="step-3" cols="12">
              <b-form-group v-if="tests" label="Confirm the test topic you selected or choose a new one.">
                <b-form-radio v-for="(test, index) in tests" :id="'test-group-' + index"
                              :key="index"
                              v-model="selectedTestForm.selectedTest"
                              :value="test.id"
                              :class="index === selectedTestForm.selectedTest ? 'selected-radio': 'stock-radio'"
                              class="m-2 hover-border">
                  <b-row>
                    <b-col>
                      <p :id="'test-name-' + index" class="radio-subject m-0">
                        {{ test.name }}
                      </p>
                      <p>{{ test.description }}</p>
                    </b-col>
                  </b-row>
                </b-form-radio>
              </b-form-group>

              <b-row class="my-3">
                <b-col cols="6">
                  <b-button variant="outline-primary" block @click="currentStep--">
                    Back
                  </b-button>
                </b-col>
                <b-col cols="6">
                  <b-button id="finish-button" variant="primary" block
                            :class="{ 'disabled': selectedTestForm.selectedTest == null }" @click="finish()">
                    Finish
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-show="currentStep === 4" id="step-4" cols="12">
              <p class="my-2">
                Share the unique test link with your team to start collecting results.
              </p>
              <ShareLinkForm :clipboard-value="clipboardValue" />
              <b-link id="mail-to" class="my-3" :href="'mailto:?subject=' + emailText.subject + '&body=' + getEmailBody()">
                <font-awesome-icon icon="envelope" /> Share test link via e-mail
              </b-link>
              <p class="my-3">
                Once your team has completed the Skills Analyzer test, you'll be able to start analyzing skill levels on your Results Dashboard
              </p>
              <b-row>
                <b-col cols="10" offset="1" class="my-3">
                  <b-button id="go-to-test-button" variant="primary" block
                            @click="goToTest()">
                    Go to Your Results Dashboard
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ShareLinkForm from '@/components/ShareLinkForm';

export default {
  name: 'SkillsgapTestBuilder',
  components: { ShareLinkForm },
  data() {
    return {
      clipboardValue: 'Default',
      currentStep: 1,
      formKey: {1: 'infoForm', 2: 'nameForm', 3: 'selectedTestForm'},
      infoForm: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        title: null,
        company: null,
      },
      nameForm: {
        testName: null,
      },
      max: 4,
      selectedTestForm: {
        selectedTest: null,
      },
      submittedGroupTest: null,
      emailText: {
        subject: 'You\'ve Been Invited to SolidProfessor Skills Analyzer',
        body: 'You\'ve been invited to test your skills with the SolidProfessor Skills Analyzer!'
      },
    };
  },
  computed: {
    selectedTestId() {
      return this.$store.getters['sptests/getSelectedTestId'];
    },
    tests() {
      return this.$store.getters['sptests/getTestBank'];
    },
  },
  beforeCreate() {
    if (this.$store.getters['sptests/getSelectedTestId'] === null) {
      this.$router.push({name: 'skillsgapTestSelection', params: {userType: 'business'}}).catch(err => {});
    }
  },
  mounted() {
    if (this.selectedTestId !== null) {
      this.selectedTestForm.selectedTest = this.selectedTestId;
    }
  },
  methods: {
    /**
     * Computes the 'copy-link' value as a string
     * NOTE: requires this.submittedGroupTest.id
     * @returns {string}
     */
    computePlaceholder() {
      if (this.submittedGroupTest && this.submittedGroupTest.uuid) {
        return this.SPENV.WWW_URL + '/skillsgap/business/' + this.submittedGroupTest.uuid;
      }
    },
    /**
     * Lookup display text by index (currentStep in form)
     * @param index
     * @returns {*}
     */
    getFormName(index){
      const formNames = {
        1 : 'Set Up the Skills Analyzer for Your Team',
        2: 'Give Your Test a Name',
        3: 'Choose Your Test Topic',
        4: 'Your SolidProfessor Skills Analyzer is Ready to Go.',
        5: 'Share the Test Link with Your Team'
      };
      return formNames[index];
    },
    goToTest() {
      this.$router.push({
        name: 'skillsgapResults',
        params: { resultsId: this.submittedGroupTest.uuid }
      }).catch(err => {});
    },
    /**
     * For given form, checks very value is populated
     * USAGE: All fields in all forms in template are required, checks for ALL
     * @param theForm
     * @returns {boolean}
     */
    isFormInValid(theForm) {
      return Object.keys(theForm).every(key => theForm[key]);
    },
    /**
     * Checks current form for errors in fields, and that every field is poplated
     * If true, proceeds
     * @param theForm
     */
    next(theForm) {
      if (this.errors.any() || !this.isFormInValid(theForm)) {
        return;
      }
      this.currentStep++;
    },
    back(theForm) {
      if (this.errors.any()) {
        theForm.testName = null;
        // this will reset all the fields in the form, using this.errors.clear() only cleans the errors list
        this.$validator.reset();
      }
      this.currentStep--;
    },
    /**
     * Gathers all form data and sends to API,
     * On success, displays next step
     *
     * @return {void} -- changes template to next 'step'
     */
    finish() {
      if (this.selectedTestForm.selectedTest == null) {
        return;
      }
      let data = {
        test_name: this.nameForm.testName,
        source_test_id: this.selectedTestForm.selectedTest,
        first_name: this.infoForm.firstName,
        last_name: this.infoForm.lastName,
        email: this.infoForm.email,
        company_name: this.infoForm.company,
        user_title: this.infoForm.title,
        phone_number: this.infoForm.phone,
        is_admin: true,
        idcustomer: this.user.id ? this.user.id: null,
      };
      let loader = this.$loading.show();
      this.$store.dispatch('sptests/saveGroup', data).then((response)=>{
        if (response.status === 200 || response.status === 201) {
          this.submittedGroupTest = response.data;
          this.clipboardValue = this.computePlaceholder();
          this.currentStep++;
          loader.hide();
        }
      });
    },
    /**
     * Calculates display text for form
     * @param currentStep
     * @returns {string}
     */
    stepText(currentStep) {
      let returnText ='';
      if (currentStep < this.max) {
        returnText = 'Step ' + String(currentStep) + '/' + String(this.max - 1);
      } else {
        returnText = 'Finished';
      }
      return returnText;
    },
    /**
     * Encodes the body of the mailto:
     * @returns {string}
     */
    getEmailBody () {
      return encodeURIComponent(this.emailText.body + '\n\n\nGo to the following link to take the test:\n' + this.clipboardValue);
    }
  }
};
</script>

<style lang="scss">
.test-builder-1 {
  background-color: #F8F8F8;
  padding-top: 5vh;
  padding-bottom: 20vh;

  .background-white {
    background-color: #FFFFFF;
  }
  .builder-question {
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-sizing: border-box;
    border-radius: 4px;
  }
  .blue-button {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    background: #00295B;
    border-color: #00295B;
    &:hover {
      background: #1A4A86;
      border-color: #1A4A86;
    }
  }
  .copy-link {
    margin-right: 0 !important;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }
  .custom-control-label {
    margin: 10px;
  }
  .custom-control-label::before, .custom-control-label::after {
    margin-top: 5px;
  }
  .force-form-width {
    width: 60%;
  }
  .green {
    color: $sp_green;
  }
  .heavy-font {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
  h3 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
  }
  .hover-border:hover {
    border: 1px solid $sp_green;
  }
  .over-pad {
    padding-left: 4vw;
    padding-right: 4vw;
  }
  .progress {
    background-color: #D0D0D0;
  }
  .radio-subject {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 36px;
    color: #414141;
  }
  .stock-radio {
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .selected-radio {
    border: 1px solid $sp_green;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .stay-thick {
    height: 20px;
  }
}
</style>
